import { toCanvas, toPng } from 'html-to-image';

export const generatePNG = async (
  ref: HTMLDivElement | any,
  link: HTMLAnchorElement,
  name: string,
  refChild?: HTMLDivElement | any,
  height?: string,
  customClass?: string,
  customRef?: HTMLDivElement | any
) => {
  ref.current.className = customRef
    ? ''
    : 'w-fit h-full items-center text-center rounded-lg bg-white min-w-full min-h-full';
  if (refChild)
    refChild.current.className = `relative p-2 ${
      height ? height : 'h-[15rem]'
    }`;
  if (customRef)
    customRef.current.className =
      'w-fit h-full items-center text-center rounded-lg bg-white min-w-full min-h-full mt-4';
  await toPng(ref.current, { cacheBust: true })
    .then((dataUrl) => {
      link.download = `${name}.png`;
      link.href = dataUrl;
      link.click();
      ref.current.className = customRef
        ? ''
        : 'w-full h-full items-center text-center rounded-lg bg-white min-w-full min-h-full';
      if (customRef) customRef.current.className = customClass;

      if (refChild)
        refChild.current.className = `relative p-2 overflow-y-hidden scrollbar-thin scrollbar-thumb-gray-900 scrollbar-thumb-rounded-full ${
          height ? height : 'h-[15rem]'
        }`;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const generateFullPagePNG = (
  printed_div: HTMLElement,
  png_title = 'komete',
  cb?: () => void
) => {
  toCanvas(printed_div, {
    skipAutoScale: true,
    width: printed_div.scrollWidth + 300,
    canvasWidth: printed_div.scrollWidth + 300,
  })
    .then(function (canvas: HTMLCanvasElement) {
      const dataUrl = canvas.toDataURL('img/png');
      const a = document.createElement('a'); //Create <a>
      a.href = dataUrl; //Image Base64 Goes here
      a.download = png_title; //File name Here
      a.click(); //Downloaded file
      cb?.();
    })
    .catch(function (error: any) {
      console.error('oops, something went wrong!', error);
      throw new Error(error);
    });
};



export async function exportToImage(
  div: HTMLDivElement,
  fileName: string,
  kpiDiv: HTMLDivElement | null = null
) {
  if (kpiDiv !== null)
    kpiDiv.className = kpiDiv.className.replace('max-h-[30rem]', 'max-h-full');
  const html2canvas = (await import('html2canvas')).default;
  const canvas = await html2canvas(div);
  const imageURL = canvas.toDataURL('image/png');
  const link = document.createElement('a');
  link.href = imageURL;
  link.download = `${fileName}.png`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  if (kpiDiv !== null)
    kpiDiv.className = kpiDiv.className.replace('max-h-full', 'max-h-[30rem]');

  return `${fileName}.png`;
}