/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { isEmpty, set } from 'lodash';
import XLSX from 'xlsx';
import type { ReturnedRow } from 'database/bigQueryHelper';

const createNumberCell = (
  cell: any,
  newRow: any[],
  possibleNumberInCell: any
) => {
  let formating = typeof cell === 'string' ? cell.replace(/\d/g, '0') : '';
  while (formating.startsWith('00')) {
    formating = formating.substring(1);
  }

  // round float to 2 decimal
  const pointIndex = formating.indexOf('.');
  if (pointIndex !== -1) {
    const afterPoint = formating.split('.')[1];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const zeroCount = (afterPoint!.match(new RegExp('0', 'g')) ?? []).length;
    const debut = formating.substring(0, pointIndex + 1);
    formating = debut + '00' + formating.substring(pointIndex + 1 + zeroCount);
  }

  newRow.push({
    t: 'n', // numeric cell
    v:
      typeof cell === 'string'
        ? cell.endsWith('%') || cell.startsWith('%')
          ? possibleNumberInCell / 100
          : possibleNumberInCell
        : cell, // underlying value
    z: formating, //, // number format
    w: cell, // formatted text
  });
};

const convertStringNumberToNumberForPage = (pages: any) => {
  const pagesWithNumber: any[][] = [];
  //convert string of number to cell number
  pages.forEach((row: any[]) => {
    const newRow: any[] = [];
    row.forEach((cell: any) => {
      const possibleNumberInCell = Number(cell);
      const possibleNumberWithEndingSymbolInCell = Number(
        typeof cell === 'string' ? cell.substring(0, cell.length - 1) : cell
      );
      const possibleNumberWithBegginingSymbolInCell = Number(
        typeof cell === 'string' ? cell.substring(1) : cell
      );
      if (!isNaN(possibleNumberInCell)) {
        createNumberCell(cell, newRow, possibleNumberInCell);
      } else if (
        !isNaN(possibleNumberWithEndingSymbolInCell) &&
        (cell.endsWith('%') || cell.endsWith('€'))
      ) {
        createNumberCell(cell, newRow, possibleNumberWithEndingSymbolInCell);
      } else if (
        !isNaN(possibleNumberWithBegginingSymbolInCell) &&
        (cell.startsWith('%') || cell.startsWith('€'))
      ) {
        createNumberCell(cell, newRow, possibleNumberWithBegginingSymbolInCell);
      } else {
        newRow.push(cell);
      }
    });
    pagesWithNumber.push(newRow);
  });

  return pagesWithNumber;
};

// format sheet name https://support.microsoft.com/en-us/office/rename-a-worksheet-3f1f7148-ee83-404d-8ef0-9ff99fbad1f9
const formatSheetName = (name: string) => {
  // remove forbidden characteres
  const pattern = /[\\/?*:[]/g;
  let result = name.replace(pattern, '');
  // max size for sheet name is 31 characters
  result = result.substring(0, 30);
  // remove leading and trailing apostrophes
  while (result.startsWith("'")) {
    result = result.substring(1);
  }
  while (result.endsWith("'")) {
    result = result.substring(0, result.length - 1);
  }
  // remove forbidden name "History"
  if (result === 'History' || result === '') return 'Sheet';
  return result;
};


// TODO merge function generateXlsx && exportToExcel
export const generateXlsx = (
  data: Record<string, string[][]>,
  filename: string,
  keyfigures: Record<string, (string | number)[][]>,
  cb?: () => void
  // sheet_name?: string
) => {
  /* create a new workbook */
  const wb = XLSX.utils.book_new();

  /* create pages */
  /**
   * data = { page1_name: page1_data, page2_name: page2_data }
   */

  if (!isEmpty(keyfigures)) {
    const keyfigures_ws = XLSX.utils.aoa_to_sheet([]);
    let idx = 1;
    for (const key in keyfigures) {
      if (key === 'type') continue;

      XLSX.utils.sheet_add_aoa(keyfigures_ws, [[key]], { origin: `A${idx}` });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      XLSX.utils.sheet_add_aoa(keyfigures_ws, keyfigures[key]!, {
        origin: `A${idx + 1}`,
      });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      idx = idx + 1 + keyfigures[key]!.length + 1; // +1: space for title, +1: space left between key figures
    }

    // "Filtres" is an overwritten value, it must be variable, not hardcoded
    XLSX.utils.book_append_sheet(
      wb,
      keyfigures_ws,
      Object.keys(keyfigures).includes('type')
        ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        (keyfigures.type![0]?.[0] as string)
        : 'Filtres'
    );
  }

  for (const page in data) {
    const result = formatSheetName(page);
    const pagesWithNumber = convertStringNumberToNumberForPage(data[page]);

    const ws = XLSX.utils.aoa_to_sheet(pagesWithNumber);
    XLSX.utils.book_append_sheet(wb, ws, result);
  }

  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, `${filename}.xlsx`);

  cb?.();
};


export function exportToExcel(
  kpiData: Record<string, string | number>[],
  fileName: string,
  activeFilters?: Record<string, string>[]
) {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(kpiData);
  if (activeFilters) {
    const ws2 = XLSX.utils.json_to_sheet(activeFilters);
    XLSX.utils.book_append_sheet(wb, ws2, 'Filtres');
  }
  XLSX.utils.book_append_sheet(wb, ws, 'Données');
  return XLSX.writeFile(wb, `${fileName}.xlsx`) as unknown;
}

// export async function exportToCsv(kpiData: KpiData[], fileName: string) {
//   const XLSX = require('xlsx');
//   const wb = XLSX.utils.book_new();
//   const ws = XLSX.utils.json_to_sheet(kpiData);
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//   return XLSX.writeFile(wb, `${fileName}.csv`) as unknown;
// }
//

export function handleExportReportExcel (
  table: {
    columns: Record<string, string>;
    data: { map: (arg0: (obj: ReturnedRow) => string[]) => never[][] };
    filterToExport?: string[][];
  },
  suffix?:string | undefined
)  {
  const xlsx_pages = {}; // 1 page = 1 kpi // object of arrays of arrays => 1st line of the main array = labels; other lines = data; KEY = page name; DATA: xlsx table
  const csv_filename = 'Listes des formations - Moteur de recherche Komète';

  const keyfigures_page = {};
  const sortedKeysLabel: string[] = Object.keys(table.columns);
  const sortedObjLabel: Record<string, string> = {};
  sortedKeysLabel.forEach((key) => {
    sortedObjLabel[key] = table.columns[key] ?? '';
  });
  const kpi_labels = Object.values(sortedObjLabel);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const kpi_data = table.data.map((obj: ReturnedRow) => {
    const sortedObjValue: Record<string, string> = {};
    sortedKeysLabel.forEach((key) => {
      if (key === 'evolution_rate')
        sortedObjValue[key] = obj[key]
          ? obj[key] === 0
            ? '-'
            : (obj[key] as number).toFixed(0) + '%'
          : '';
      else if (key.includes('evolution_rate_'))
        sortedObjValue[key] =
          typeof obj[key] === 'number'
            ? (Math.round(obj[key] * 100) / 100).toFixed(2) + '% '
            : '';
      else if (key === 'nb')
        sortedObjValue[key] =
          obj[key] && suffix
            ? obj[key].toString() + ' ' + suffix
            : (obj[key]?.toString() ?? '');
      else sortedObjValue[key] = obj[key]?.toString() ?? '';
    });
    return Object.values(sortedObjValue);
  }) ?? [[]];
  // data est un array d'array, chaque array contenu dans data représente une ligne du fichier excel
  const data = [];
  data.push(['Filtres']);
  // if (filters) data.push(...filters);
  if (table.filterToExport) data.push(...table.filterToExport);

  data.push([]);
  data.push(kpi_labels);
  data.push(...kpi_data);

  set(xlsx_pages, 'title', data);
  generateXlsx(xlsx_pages, csv_filename, keyfigures_page);
}


